import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      Hello to the interweb.
    </div>
  );
}

export default App;
